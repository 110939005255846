exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".main-module-root{background-color:rgb(var(--venia-global-color-background));color:rgb(var(--venia-global-color-text));position:relative;z-index:1}.main-module-page{margin:0 auto;max-width:var(--venia-global-maxWidth);min-height:100vh}.main-module-pageSize{min-height:unset}.main-module-close_icon{color:#1a1919 !important;border:1px solid rgba(26,25,25,.1) !important;border-radius:50% !important;padding:10px !important;box-sizing:border-box;text-align:center;vertical-align:middle;width:48px;height:48px}.main-module-close_icon svg{width:12px}.main-module-close_icon_white{color:#fffefe !important;border:2px solid rgba(255,254,254,.4) !important}.main-module-close_icon_red{color:#992631 !important;border:2px solid rgba(153,38,49,.2) !important}@media(max-width: 959px){.main-module-close_icon{display:none !important}}@media(min-width: 1120px){.main-module-offcanvasRegistration{width:950px}}.uk-offcanvas-overlay::before{background:rgba(26,25,25,.2)}a{text-rendering:auto}", ""]);

// Exports
exports.locals = {
	"root": "main-module-root",
	"page": "main-module-page",
	"pageSize": "main-module-pageSize",
	"close_icon": "main-module-close_icon",
	"close_icon_white": "main-module-close_icon_white main-module-close_icon",
	"close_icon_red": "main-module-close_icon_red main-module-close_icon",
	"offcanvasRegistration": "main-module-offcanvasRegistration"
};