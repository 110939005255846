import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import actions from "../actions/bookTable";
import { setCityReducerObject, defaultStoreCity } from "../../../../redux/bookTable/BookTable.reducer";
export var name = "bookTable";
var initialState = _objectSpread(_objectSpread({}, defaultStoreCity), {}, {
  search: {
    city: null,
    date: null,
    hour: null,
    people: null,
    submitted: false
  },
  booking: {
    date: null,
    hour: null,
    ls_store_id: null
  }
});
var reducerMap = _objectSpread(_objectSpread({
  [actions.begin]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), payload);
  },
  [actions.edit]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    return _objectSpread(_objectSpread({}, state), payload);
  },
  [actions.search.submit]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    return _objectSpread(_objectSpread({}, state), {}, {
      search: _objectSpread(_objectSpread({}, payload), {}, {
        submitted: true
      })
    });
  },
  [actions.booking.edit]: (state, _ref4) => {
    var {
      payload
    } = _ref4;
    return _objectSpread(_objectSpread({}, state), {}, {
      booking: _objectSpread(_objectSpread({}, state.booking), payload)
    });
  },
  [actions.booking.accept]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      search: {
        city: null,
        date: null,
        hour: null,
        people: null,
        submitted: false
      },
      booking: _objectSpread(_objectSpread(_objectSpread({}, state.booking), payload), {}, {
        submitting: false
      })
    });
  },
  [actions.booking.reject]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), {}, {
      booking: _objectSpread(_objectSpread({}, state.booking), {}, {
        error: payload
      })
    });
  }
}, setCityReducerObject), {}, {
  [actions.reset]: (state, payload) => {
    return _objectSpread(_objectSpread({}, initialState), {}, {
      // do not remove this
      cities: state.cities,
      storesFullData: state.storesFullData
    });
  }
});
export default handleActions(reducerMap, initialState);