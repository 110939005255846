exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".footerNav-module-root{z-index:10000;background:#ede5d5;border-top:1px solid #fff9ec;padding-top:12px}.footerNav-module-root .footerNav-module-mainCta{aspect-ratio:1/1;background-color:#992631;margin-bottom:calc(2px + env(safe-area-inset-bottom)*.7);border-radius:100%;color:#fff9ec;min-width:65px;width:8vh;max-width:70px;box-shadow:0px 0px 3px 1px rgba(0,0,0,.38)}.footerNav-module-root .footerNav-module-mainCta i:before{font-size:40px}.footerNav-module-root .footerNav-module-mainCta.footerNav-module-tableIcon i:before{font-size:35px}.footerNav-module-root .footerNav-module-mainCta .footerNav-module-mainCtaTable{white-space:pre-line;line-height:1.2}@keyframes footerNav-module-pulse{0%,20%,100%{transform:scale(1)}10%{transform:scale(1.1)}}.footerNav-module-root .footerNav-module-pulseButtonAnimation{animation:footerNav-module-pulse 5s infinite}", ""]);

// Exports
exports.locals = {
	"root": "footerNav-module-root",
	"mainCta": "footerNav-module-mainCta",
	"tableIcon": "footerNav-module-tableIcon",
	"mainCtaTable": "footerNav-module-mainCtaTable",
	"pulseButtonAnimation": "footerNav-module-pulseButtonAnimation",
	"pulse": "footerNav-module-pulse"
};