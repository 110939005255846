exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".BookFormModalField-module-Selector{width:100%;display:flex;border-radius:10px;background-color:#fff9ec;height:50px}.BookFormModalField-module-Selector.BookFormModalField-module-disabled:not(.BookFormModalField-module-minimalView){background-color:rgba(249,247,246,.2)}.BookFormModalField-module-Selector.BookFormModalField-module-minimalView{background-color:rgba(0,0,0,0)}.BookFormModalField-module-Selector .BookFormModalField-module-selectorContent{display:flex;align-items:center;margin:0 auto 0 10px;padding-left:15px}.BookFormModalField-module-Selector .BookFormModalField-module-selectorContent span{font-size:16px}.BookFormModalField-module-Selector .BookFormModalField-module-selectorPrompt{display:flex;align-items:center}.BookFormModalField-module-Selector .BookFormModalField-module-selectorPrompt span{font-size:12px;color:#992631}.BookFormModalField-module-Selector.BookFormModalField-module-selected{border:0px solid;border-color:#ccc;padding-right:0}.BookFormModalField-module-Selector.BookFormModalField-module-selected .BookFormModalField-module-selectorContent{border-color:#ccc}.BookFormModalField-module-Selector.BookFormModalField-module-selected .BookFormModalField-module-selectorPrompt span{color:#992631}", ""]);

// Exports
exports.locals = {
	"Selector": "BookFormModalField-module-Selector",
	"disabled": "BookFormModalField-module-disabled",
	"minimalView": "BookFormModalField-module-minimalView",
	"selectorContent": "BookFormModalField-module-selectorContent",
	"selectorPrompt": "BookFormModalField-module-selectorPrompt",
	"selected": "BookFormModalField-module-selected"
};