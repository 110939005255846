import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import actions from "../actions/app";
export var name = "app";
var initialState = {
  drawer: null,
  hasBeenOffline: !navigator.onLine,
  isOnline: navigator.onLine,
  isPageLoading: true,
  pageLoaderFlags: [{
    name: "default",
    value: true
  }],
  overlay: false,
  pending: {},
  searchOpen: false,
  stores: [],
  footer_nav: {
    visible: false
  },
  modalsOpened: {}
};
var reducerMap = {
  [actions.toggleDrawer]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), {}, {
      drawer: payload,
      overlay: !!payload
    });
  },
  [actions.toggleSearch]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      searchOpen: !state.searchOpen
    });
  },
  [actions.setOnline]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isOnline: true
    });
  },
  [actions.setOffline]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isOnline: false,
      hasBeenOffline: true
    });
  },
  [actions.setPageLoading]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    var updatedFlags = updateOrAddFlag(state.pageLoaderFlags, "default", !!payload);
    return _objectSpread(_objectSpread({}, state), {}, {
      pageLoaderFlags: updatedFlags,
      isPageLoading: calcuateIsPageLoading(updatedFlags)
    });
  },
  [actions.setPageLoaderFlag]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    var updatedFlags = updateOrAddFlag(state.pageLoaderFlags, payload.name, payload.value);
    return _objectSpread(_objectSpread({}, state), {}, {
      pageLoaderFlags: updatedFlags,
      isPageLoading: calcuateIsPageLoading(updatedFlags)
    });
  },
  [actions.removePageLoaderFlag]: (state, _ref4) => {
    var {
      payload
    } = _ref4;
    var updatedFlags = state.pageLoaderFlags.filter(flag => flag.name !== payload.name);
    return _objectSpread(_objectSpread({}, state), {}, {
      pageLoaderFlags: updatedFlags,
      isPageLoading: calcuateIsPageLoading(updatedFlags)
    });
  },
  [actions.setStores]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      stores: payload
    });
  },
  [actions.setFooterVisible]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), {}, {
      footer_nav: _objectSpread(_objectSpread({}, state.footer_nav), {}, {
        visible: payload
      })
    });
  },
  [actions.setModalsOpened]: (state, _ref7) => {
    var {
      payload
    } = _ref7;
    return _objectSpread(_objectSpread({}, state), {}, {
      modalsOpened: payload
    });
  }
};
var calcuateIsPageLoading = pageLoaderFlags => {
  if (pageLoaderFlags.length === 1 && pageLoaderFlags[0].name === "default") {
    return pageLoaderFlags[0].value;
  } else {
    return pageLoaderFlags.filter(flag => flag.name !== "default").some(flag => flag.value);
  }
};
var updateOrAddFlag = (flags, name, value) => {
  var flagIndex = flags.findIndex(flag => flag.name === name);
  if (flagIndex !== -1) {
    // If the flag exists, update its value
    return flags.map((flag, index) => index === flagIndex ? _objectSpread(_objectSpread({}, flag), {}, {
      value
    }) : flag);
  } else {
    // If the flag does not exist, add it to the array
    return [...flags, {
      name,
      value
    }];
  }
};
export default handleActions(reducerMap, initialState);