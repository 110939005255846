exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".myAccountOffCanvas-module-myAccountOffCanvas{color:#fffefe;min-height:100vh;padding:0 80px;background:var(--background-mobile)}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-money{margin-top:auto}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-money h2{font-size:56px;margin:0}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-money h2 span{font-size:24px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-money .myAccountOffCanvas-module-label{font-size:18px;font-weight:700;margin-bottom:28px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-money .myAccountOffCanvas-module-info{font-size:18px;margin-bottom:28px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds{margin-top:auto;margin-bottom:200px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds .myAccountOffCanvas-module-step .myAccountOffCanvas-module-number{font-family:\"Knockout-73FullHeviweight\"}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds .myAccountOffCanvas-module-step .myAccountOffCanvas-module-number:first-child{opacity:1;font-weight:bold;font-size:56px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds .myAccountOffCanvas-module-step .myAccountOffCanvas-module-number:nth-child(2){opacity:.4;font-size:24px;margin:auto 6px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds .myAccountOffCanvas-module-label{font-size:18px;font-weight:700;margin-bottom:28px}.myAccountOffCanvas-module-myAccountOffCanvas .myAccountOffCanvas-module-seeds .myAccountOffCanvas-module-info{font-size:18px;margin-bottom:28px}", ""]);

// Exports
exports.locals = {
	"myAccountOffCanvas": "myAccountOffCanvas-module-myAccountOffCanvas",
	"money": "myAccountOffCanvas-module-money",
	"label": "myAccountOffCanvas-module-label",
	"info": "myAccountOffCanvas-module-info",
	"seeds": "myAccountOffCanvas-module-seeds",
	"step": "myAccountOffCanvas-module-step",
	"number": "myAccountOffCanvas-module-number"
};