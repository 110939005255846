import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
// ##### OVERRIDE ONLY FOR URL IMAGES ######

import { env } from "build/env";

// If the root template supplies the backend URL at runtime, use it directly
var htmlDataset = document.querySelector("html").dataset;
var {
  imageOptimizingOrigin
} = htmlDataset;
// Protect against potential falsy values for `mediaBackend`.
var mediaBackend = htmlDataset.mediaBackend;
if (!mediaBackend) {
  console.warn("A media backend URL should be defined in your config.");
  mediaBackend = "https://backend.test/media/";
}
var useBackendForImgs = imageOptimizingOrigin === "backend";

// Tests if a URL begins with `http:` or `https:` or `data:`
var absoluteUrl = /^(data|http|https)?:/i;

// Simple path joiner that guarantees one and only one slash between segments
var joinUrls = function () {
  var base = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "/";
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "/";
  return (base.endsWith("/") ? base.slice(0, -1) : base) + "/" + (url.startsWith("/") ? url.slice(1) : url);
};
var mediaBases = new Map().set("image-product", "catalog/product/").set("image-category", "catalog/category/").set("image-swatch", "attribute/swatch/");
var getFileType = url => {
  var fileName = url.pathname.split("/").reverse()[0];
  var fileType = fileName.split(".").reverse()[0];
  return fileType;
};

/**
 * Creates an "optimized" url for a provided relative url based on
 * requested media type and width. Any image URLs (whose type begins with
 * "image-" will also be optimized.)
 *
 * If a `type` is provided the `path` will be joined with the associated media
 * base.
 *  - `catalog/product/path/to/img.jpg`
 *
 * If a `width` is provided, "resize parameters" are added to the URL for
 * middlewares (either onboard or backend) to return using the desired width
 * and original media url.
 *  - `catalog/product/path/to/img.jpg?width=500&auto=webp&format=pjpg
 *
 * If only `path` is provided it is returned unaltered.
 *
 * @param {string} path - absolute or relative url to resource.
 * @param {Object} props - properties describing desired optimizations
 * @param {string} props.type - "image-product" or "image-category"
 * @param {number} props.width - the desired resize width of the image
 * @param {number} props.height - the desired resize height of the image
 * @param {number} props.quality - the desired quality of the image
 * @param {bool} props.crop - should the image be cropped
 * @param {string} props.fit - how should the image be fit with the dimensions: bounds, cover, crop
 */
var makeOptimizedUrl = function (path) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var {
      type
    } = _ref,
    opts = _objectWithoutProperties(_ref, _excluded);
  // Immediate return if there's no image optimization to attempt
  if (!type || !type.startsWith("image-")) {
    return path;
  }
  var {
    origin
  } = window.location;
  var isAbsolute = absoluteUrl.test(path);
  var magentoBackendURL = env.MAGENTO_BACKEND_URL;
  var baseURL = new URL(path, mediaBackend);

  // If URL is relative and has a supported type, prepend media base onto path
  if (!isAbsolute && mediaBases.has(type)) {
    var mediaBase = mediaBases.get(type);
    if (!baseURL.pathname.includes(mediaBase)) {
      baseURL = new URL(joinUrls(mediaBase, path), mediaBackend);
    }
  }
  if (baseURL.href.startsWith(magentoBackendURL) && !useBackendForImgs) {
    // Replace URL base so optimization middleware can handle request
    baseURL = new URL(baseURL.href.slice(magentoBackendURL.length), origin);
  }

  // Append image optimization parameters
  var params = new URLSearchParams(baseURL.search);
  params.set("auto", "webp"); // Use the webp format if available

  var imageFileType = getFileType(baseURL);
  if (imageFileType === "png") {
    params.set("format", "png"); // use png if webp is not available
  } else {
    params.set("format", "pjpg"); // Use progressive JPG if webp is not available
  }
  Object.entries(opts).forEach(_ref2 => {
    var [key, value] = _ref2;
    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });
  baseURL.search = params.toString();
  if (magentoBackendURL && baseURL.origin === origin) {
    return magentoBackendURL + baseURL.href.slice(baseURL.origin.length);
  }
  return baseURL.href;
};
export default makeOptimizedUrl;