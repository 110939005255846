var filterFunctions = {
  filterByAllergen: (filterArray, filterKey, product, products) => {
    //Currently we don't have allergens in the product catalog
    return true;
    // if (
    //     !(
    //         products &&
    //         product &&
    //         products.find(productFull => productFull.sku === product.sku) &&
    //         products.find(productFull => productFull.sku === product.sku)[filterKey]
    //     )
    // )
    //     return true;
    // else
    //     return !filterArray.some(filterValue =>
    //         products.find(productFull => productFull.sku === product.sku)[filterKey].includes(filterValue)
    //     );
  },
  filterByAttribute: (filterArray, filterKey, product, products) => {
    //We need to recover the product from productCatalog in order to have the correct attributes (reconciliation vegeratian vs vegetariano)
    var productsEnriched = products.find(x => x.sku === product.sku);
    return productsEnriched && productsEnriched[filterKey] && (filterArray.every(filterValue => productsEnriched[filterKey].includes(filterValue)) || productsEnriched.lsr_is_deal);
  },
  filterByMemberAttribute: (filterArray, filterKey, product, products) => {
    //We need to recover the product from productCatalog in order to have the correct attributes (reconciliation vegeratian vs vegetariano)
    var productsEnriched = products.find(x => x.sku === product.sku);
    var productMemberAttribute = productsEnriched[filterKey];
    var check = productMemberAttribute === null || productMemberAttribute.length === 0 || filterArray.some(filterValue => productMemberAttribute.includes(filterValue));
    return check;
  }
};
export var filterProducts = (filters, unfilteredProducts, products) => {
  var filteredProducts = unfilteredProducts;
  Object.keys(filters).forEach(filterKey => {
    filteredProducts = filteredProducts.filter(product => filterFunctions[filters[filterKey].type](filters[filterKey].values, filterKey, product, products));
  });
  return filteredProducts;
};
export var filterOptions = [{
  name: "vegan",
  label: "Vegano",
  color: "#698e5f"
}, {
  name: "vegetariano",
  label: "Vegetariano*",
  color: "#d48036"
}];