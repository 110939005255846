import { actionTypesSimple } from "./BookTable.actionTypes";

/**
 *
 * @typedef {{
 *  name: string,
 *  latitude: number,
 *  longitude: number
 * }} CityLatLong
 */

/**
 *
 * @param {CityLatLong[]} cities
 */
export var setCities = cities => ({
  type: actionTypesSimple.SET_CITIES,
  payload: cities
});

/**
 *
 * @typedef {{
 *  ls_store_id: string,
 *  name: string,
 *  status: status,
 *  frontend_name: string,
 *  latitude,
 *  longitude,
 *  store_preview_image_mobile,
 *  store_preview_image_desktop,
 *  address: {
 *      address1: string,
 *      address2,
 *      city: string,
 *      phone_number: string,
 *      region
 *  },
 *  store_hours: {
 *      day,
 *      from,
 *      to,
 *      type
 *  }[]
 * }} StoreFullData
 */

/**
 *
 * @param {StoreFullData[]} stores
 */
export var setStoresFull = stores => ({
  type: actionTypesSimple.SET_STORES_FULL,
  payload: stores
});

/**
 *
 * @param {StoreFullData} store
 */
export var setSelectedStore = store => ({
  type: actionTypesSimple.SET_SELECTED_STORE,
  payload: store
});

/**
 *
 * @param {StoreFullData} store
 */
export var setPreviousBookings = store => ({
  type: actionTypesSimple.SET_PREVIOUS_BOOKINGS,
  payload: store
});

/**
 *
 * @param {StoreFullData} store
 */
export var setPreviouValues = values => ({
  type: actionTypesSimple.SET_PREVIOUS_VALUES,
  payload: values
});

/**
 *
 * @param {string} contactId
 */
export var setContactId = contactId => ({
  type: actionTypesSimple.SET_CONTACT_ID,
  payload: contactId
});

/**
 *
 * @param {boolean} willBeOpen
 */
export var setModalBookATableOpen = willBeOpen => ({
  type: actionTypesSimple.SET_MODAL_OPEN,
  payload: !!willBeOpen
});

/**
 *
 * @param {boolean} willBeOpen
 */
export var setModalFeedbackBookingEditOpen = willBeOpen => ({
  type: actionTypesSimple.SET_MODAL_OPEN,
  payload: !!willBeOpen
});

/**
 *
 * @param {boolean} isEditFlow
 */
export var setIsEditFlow = isEditFlow => ({
  type: actionTypesSimple.SET_IS_EDIT_FLOW,
  payload: !!isEditFlow
});

/**
 *
 * @param {boolean} isFavRestaurantSelected
 */
export var setIsFavRestaurantSelected = isFavRestaurantSelected => ({
  type: actionTypesSimple.SET_FAV_RESTAURANT_SELECTED,
  payload: !!isFavRestaurantSelected
});

/**
 *
 * @param {boolean} FavRestaurantData
 */
export var setFavRestaurant = FavRestaurantData => ({
  type: actionTypesSimple.SET_FAV_RESTAURANT,
  payload: FavRestaurantData
});
export var setCancelBookingError = error => ({
  type: actionTypesSimple.SET_CANCEL_BOOKING_ERROR,
  payload: error
});
export var resetBook_a_Table = () => ({
  type: actionTypesSimple.RESET_BOOK_A_TABLE,
  payload: null
});