exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".createAccountPage-module-registration_container{background-color:#ede5d5;height:100dvh;width:100%}.createAccountPage-module-registration_container .createAccountPage-module-page_wrapper{background-color:#ede5d5;padding-top:calc(env(safe-area-inset-top, 0) + 66px);padding-bottom:15px}.createAccountPage-module-registration_container .createAccountPage-module-page_wrapper .createAccountPage-module-title{display:inherit;font-size:24px;margin-bottom:50px;font-weight:normal;line-height:1.17;text-align:center}.createAccountPage-module-registration_container .pass-feedback-container .password_ok{display:inline-block;font-size:13px;padding-left:8px;opacity:.5}.createAccountPage-module-registration_container .privacy-container{margin-top:40px;padding-bottom:70px}.createAccountPage-module-registration_container .button-container_registration{margin-top:30px;width:100%}.createAccountPage-module-registration_container .button-container_registration button{width:100%}@media(min-width: 1120px){.createAccountPage-module-container{max-width:500px}}", ""]);

// Exports
exports.locals = {
	"registration_container": "createAccountPage-module-registration_container",
	"page_wrapper": "createAccountPage-module-page_wrapper",
	"title": "createAccountPage-module-title",
	"container": "createAccountPage-module-container"
};