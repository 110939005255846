import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// USED THERE
// src/overrides/peregrine/store/reducers/bookTable.js

import { actionTypesSimple } from "./BookTable.actionTypes";
export var defaultStoreCity = {
  cities: [],
  storesFullData: [],
  selectedStore: null,
  previousBookings: [],
  favRestaurant: null,
  previousValues: null,
  modalOpen: false,
  modalFeedbackOpen: false,
  isThisEditFlow: false,
  isFavRestaurantSelected: false,
  cancelBookingError: null
};
var setCity = (state, _ref) => {
  var {
    payload: cities
  } = _ref;
  return _objectSpread(_objectSpread({}, state), {}, {
    cities
  });
};
var setStores = (state, _ref2) => {
  var {
    payload: storesFullData
  } = _ref2;
  return _objectSpread(_objectSpread({}, state), {}, {
    storesFullData
  });
};
var setSelectedStore = (state, _ref3) => {
  var {
    payload: selectedStore
  } = _ref3;
  return _objectSpread(_objectSpread({}, state), {}, {
    selectedStore
  });
};
var setPreviousBookings = (state, _ref4) => {
  var {
    payload: previousBookings
  } = _ref4;
  return _objectSpread(_objectSpread({}, state), {}, {
    previousBookings
  });
};
var setFavRestaurant = (state, _ref5) => {
  var {
    payload: favRestaurant
  } = _ref5;
  return _objectSpread(_objectSpread({}, state), {}, {
    favRestaurant
  });
};
var setContactId = (state, _ref6) => {
  var {
    payload: contactId
  } = _ref6;
  return _objectSpread(_objectSpread({}, state), {}, {
    contactId
  });
};
var setPreviousValues = (state, _ref7) => {
  var {
    payload: previousValues
  } = _ref7;
  return _objectSpread(_objectSpread({}, state), {}, {
    previousValues
  });
};
var setModalOpen = (state, _ref8) => {
  var {
    payload: modalOpen
  } = _ref8;
  return _objectSpread(_objectSpread({}, state), {}, {
    modalOpen
  });
};
var setModalFeedbackOpen = (state, _ref9) => {
  var {
    payload: modalFeedbackOpen
  } = _ref9;
  return _objectSpread(_objectSpread({}, state), {}, {
    modalFeedbackOpen
  });
};
var setIsEditFlow = (state, _ref10) => {
  var {
    payload: isThisEditFlow
  } = _ref10;
  return _objectSpread(_objectSpread({}, state), {}, {
    isThisEditFlow: !!isThisEditFlow
  });
};
var setFavRestaurantSelected = (state, _ref11) => {
  var {
    payload: isFavRestaurantSelected
  } = _ref11;
  return _objectSpread(_objectSpread({}, state), {}, {
    isFavRestaurantSelected: !!isFavRestaurantSelected
  });
};
var setCancelBookingError = (state, _ref12) => {
  var {
    payload: cancelBookingError
  } = _ref12;
  return _objectSpread(_objectSpread({}, state), {}, {
    cancelBookingError
  });
};
var resetBook_a_table = (state, payload) => {
  return _objectSpread({}, defaultStoreCity);
};
export var setCityReducerObject = {
  [actionTypesSimple.SET_CITIES]: setCity,
  [actionTypesSimple.SET_STORES_FULL]: setStores,
  [actionTypesSimple.SET_SELECTED_STORE]: setSelectedStore,
  [actionTypesSimple.SET_PREVIOUS_BOOKINGS]: setPreviousBookings,
  [actionTypesSimple.SET_FAV_RESTAURANT]: setFavRestaurant,
  [actionTypesSimple.SET_CONTACT_ID]: setContactId,
  [actionTypesSimple.SET_PREVIOUS_VALUES]: setPreviousValues,
  [actionTypesSimple.SET_MODAL_OPEN]: setModalOpen,
  [actionTypesSimple.SET_MODAL_FEEDBACK_OPEN]: setModalFeedbackOpen,
  [actionTypesSimple.SET_IS_EDIT_FLOW]: setIsEditFlow,
  [actionTypesSimple.SET_FAV_RESTAURANT_SELECTED]: setFavRestaurantSelected,
  [actionTypesSimple.SET_CANCEL_BOOKING_ERROR]: setCancelBookingError,
  [actionTypesSimple.RESET_BOOK_A_TABLE]: resetBook_a_table
};