import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import { gql } from "@apollo/client";
export var CREATE_ACCOUNT = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation CreateAccount(\n        $email: String!\n        $password: String!\n        $firstname: String!\n        $lastname: String\n        $date_of_birth: String\n        $gender: Int\n        $is_subscribed: Boolean\n        $has_accepted_loyalty: Boolean!\n        $has_accepted_profiling: Boolean!\n        $has_accepted_privacy: Boolean!\n    ) {\n        createCustomer(\n            input: {\n                email: $email\n                password: $password\n                firstname: $firstname\n                lastname: $lastname\n                date_of_birth: $date_of_birth\n                gender: $gender\n                is_subscribed: $is_subscribed\n                has_accepted_loyalty: $has_accepted_loyalty\n                has_accepted_profiling: $has_accepted_profiling\n                has_accepted_privacy: $has_accepted_privacy\n            }\n        ) {\n            # The createCustomer mutation returns a non-nullable CustomerOutput type\n            # which requires that at least one of the sub fields be returned.\n            customer {\n                id\n            }\n        }\n    }\n"])));
export var GET_CUSTOMER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query GetCustomerAfterCreate {\n        customer {\n            email\n            firstname\n            lastname\n            date_of_birth\n            is_subscribed\n            default_shipping\n            gender\n            addresses {\n                default_billing\n                default_shipping\n                telephone\n                city\n                street\n                region {\n                    region_code\n                    region\n                }\n                postcode\n                country_code\n                id\n            }\n            lsAccount {\n                card_id\n                contact_id\n                scheme {\n                    point_balance\n                }\n                coupons {\n                    coupon_description\n                    coupon_details\n                    coupon_expire_date\n                    offer_id\n                    barcode\n                    image\n                    short_text\n                    discount_amount\n                    discount_percentage\n                    items\n                    cta_question\n                    cta_button\n                }\n                customer_attributes {\n                    id\n                    value\n                }\n            }\n            avatar {\n                avatar\n            }\n        }\n    }\n"])));
export var SIGN_IN = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation SignInAfterCreate($email: String!, $password: String!) {\n        generateCustomerToken(email: $email, password: $password) {\n            token\n        }\n    }\n"])));
export var CREATE_CART = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation CreateCartAfterAccountCreation {\n        cartId: createEmptyCart\n    }\n"])));
export var GET_CART_DETAILS = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query GetCartDetailsAfterAccountCreation($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            items {\n                id\n                prices {\n                    price {\n                        value\n                    }\n                }\n                product {\n                    id\n                    name\n                    sku\n                    small_image {\n                        url\n                        label\n                    }\n                    price {\n                        regularPrice {\n                            amount {\n                                value\n                            }\n                        }\n                    }\n                }\n                quantity\n                ... on ConfigurableCartItem {\n                    configurable_options {\n                        id\n                        option_label\n                        value_id\n                        value_label\n                    }\n                }\n            }\n            prices {\n                grand_total {\n                    value\n                    currency\n                }\n            }\n        }\n    }\n"])));
export var MERGE_CARTS = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    mutation MergeCartsAfterAccountCreation($sourceCartId: String!, $destinationCartId: String!) {\n        mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $destinationCartId) {\n            id\n            items {\n                id\n            }\n        }\n    }\n"])));
export default {
  createAccountMutation: CREATE_ACCOUNT,
  createCartMutation: CREATE_CART,
  getCartDetailsQuery: GET_CART_DETAILS,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN
};