exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".createAccount-module-infobox{font-size:13px;line-height:1.38;opacity:.5}.createAccount-module-infoboxSuccess .createAccount-module-icon-container{margin-right:5px;background-color:#91baa8;border-radius:50%}.createAccount-module-infoboxSuccess .createAccount-module-icon-container i{font-size:20px;opacity:1}.createAccount-module-form-container_registration .createAccount-module-privacy-policy{margin-bottom:16px;display:block;color:rgba(26,25,25,.5);font-size:13px}.createAccount-module-form-container_registration .createAccount-module-privacy-policy a{color:#1a1919;text-decoration:underline}.createAccount-module-form-container_registration .createAccount-module-privacy-policy a:hover{color:rgba(26,25,25,.5)}", ""]);

// Exports
exports.locals = {
	"infobox": "createAccount-module-infobox",
	"infoboxSuccess": "createAccount-module-infoboxSuccess",
	"icon-container": "createAccount-module-icon-container",
	"form-container_registration": "createAccount-module-form-container_registration",
	"privacy-policy": "createAccount-module-privacy-policy"
};