/**
 * @fileoverview This file houses functions that can be used for
 * validation of form fields.
 *
 * Note that these functions should return a string error message
 * when they fail, and `undefined` when they pass.
 */

import { isEmailValid } from "@Src_main/utils/generalUtils";
var SUCCESS = undefined;
export var hasLengthAtLeast = (value, values, minimumLength) => {
  if (!value || value.length < minimumLength) {
    return "La tua password deve contenere almeno 8 lettere, di cui un numero, una lettera maiuscola e un carattere speciale (!*?$)";
  }
  return SUCCESS;
};
export var hasLengthAtMost = (value, values, maximumLength) => {
  if (value && value.length > maximumLength) {
    return "La tua password deve contenere almeno 8 lettere, di cui un numero, una lettera maiuscola e un carattere speciale (!*?$)";
  }
  return SUCCESS;
};
export var hasLengthExactly = (value, values, length) => {
  if (value && value.length !== length) {
    return "La tua password deve contenere almeno 8 lettere, di cui un numero, una lettera maiuscola e un carattere speciale (!*?$)";
  }
  return SUCCESS;
};

/**
 * isRequired is provided here for convenience but it is inherently ambiguous and therefore we don't recommend using it.
 * Consider using more specific validators such as `hasLengthAtLeast` or `mustBeChecked`.
 */
export var isRequired = value => {
  var FAILURE = "missing";

  // The field must have a value (no null or undefined) and
  // if it's a boolean, it must be `true`.
  if (!value) return FAILURE;

  // If it is a number or string, it must have at least one character of input (after trim).
  var stringValue = String(value).trim();
  var measureResult = hasLengthAtLeast(stringValue, null, 1);
  if (measureResult) return FAILURE;
  return SUCCESS;
};
export var mustBeChecked = value => {
  if (!value) return "Must be checked.";
  return SUCCESS;
};
export var validateRegionCode = (value, values, countries) => {
  var country = countries.find(_ref => {
    var {
      id
    } = _ref;
    return id === "US";
  });
  if (!country) {
    return 'Country "US" is not an available country.';
  }
  var {
    available_regions: regions
  } = country;
  if (!(Array.isArray(regions) && regions.length)) {
    return 'Country "US" does not contain any available regions.';
  }
  var region = regions.find(_ref2 => {
    var {
      code
    } = _ref2;
    return code === value;
  });
  if (!region) {
    return "State \"".concat(value, "\" is not an valid state abbreviation.");
  }
  return SUCCESS;
};
export var validatePassword = value => {
  var count = {
    lower: 0,
    upper: 0,
    digit: 0,
    special: 0
  };
  if (value) {
    for (var char of value) {
      if (/[a-z]/.test(char)) count.lower++;else if (/[A-Z]/.test(char)) count.upper++;else if (/\d/.test(char)) count.digit++;else if (/\S/.test(char)) count.special++;
    }
  }
  if (Object.values(count).every(value => value > 0) && value.length >= 8) {
    return "La tua password deve contenere almeno 8 lettere, di cui un numero, una lettera maiuscola e un carattere speciale (!*?$)";
  }
  return SUCCESS;
};
export var validateConfirmPassword = function (value, values) {
  var passwordKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "password";
  return value === values[passwordKey] ? SUCCESS : "Passwords must match.";
};
export var validateEmail = value => {
  return value ? isEmailValid(value) ? SUCCESS : {
    id: "validation.email",
    defaultMessage: "Must be a valid e-mail address."
  } : SUCCESS;
};
export var validatePhoneNumber = value => {
  return SUCCESS;
};