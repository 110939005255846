var prefix = "BOOKTABLE_SIMPLE";
export var actionTypesSimple = {
  SET_CITIES: "".concat(prefix, ":SET_CITIES"),
  SET_STORES_FULL: "".concat(prefix, ":SET_STORES_FULL"),
  SET_SELECTED_STORE: "".concat(prefix, ":SET_SELECTED_STORE"),
  SET_PREVIOUS_BOOKINGS: "".concat(prefix, ":SET_PREVIOUS_BOOKINGS"),
  SET_FAV_RESTAURANT: "".concat(prefix, ":SET_FAV_RESTAURANT"),
  SET_CONTACT_ID: "".concat(prefix, ":SET_CONTACT_ID"),
  SET_PREVIOUS_VALUES: "".concat(prefix, ":SET_PREVIOUS_VALUES"),
  SET_MODAL_OPEN: "".concat(prefix, ":SET_MODAL_OPEN"),
  SET_MODAL_FEEDBACK_OPEN: "".concat(prefix, ":SET_MODAL_FEEDBACK_OPEN"),
  SET_IS_EDIT_FLOW: "".concat(prefix, ":SET_IS_EDIT_FLOW"),
  SET_FAV_RESTAURANT_SELECTED: "".concat(prefix, ":SET_FAV_RESTAURANT_SELECTED"),
  SET_CANCEL_BOOKING_ERROR: "".concat(prefix, ":SET_CANCEL_BOOKING_ERROR"),
  RESET_BOOK_A_TABLE: "".concat(prefix, ":RESET_BOOK_A_TABLE")
};