import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCartContext } from "@overrides_venia/peregrine/context/cart";
import { useCheckoutContext } from "@magento/peregrine/lib/context/checkout";
import { useUserContext } from "@overrides_venia/peregrine/context/user";
import { useCatalogContext } from "@magento/peregrine/lib/context/catalog";
import { useMemo } from "react";
import * as Sentry from "@sentry/react";
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export function trackConsent(email, consent, duration) {
  exponea.track("consent", {
    event_type: "consent",
    timestamp: Date.now(),
    action: "accept",
    category: consent,
    valid_until: addDays(Date.now(), duration).getTime() / 1000,
    email: email
  });
}
export var getProductsByOptionUID = products => {
  if (!products) return {};
  var productWithOptions = products.filter(x => x.options.length > 0);
  var optionsArray = productWithOptions.map(x => x.options);
  var flatOptionsArray = [].concat.apply([], optionsArray);
  var flatOptionsValueArray = [].concat.apply([], flatOptionsArray.map(x => x.value));
  var dictOptionsValue = Object.assign({}, ...flatOptionsValueArray.map(x => ({
    [x.uid]: x
  })));
  return dictOptionsValue;
};
var getOptionsFromItem = function () {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var optionProducts = arguments.length > 1 ? arguments[1] : undefined;
  var product = arguments.length > 2 ? arguments[2] : undefined;
  if (options.length > 0) {
    var optionsFlat = [];
    options.forEach(element => {
      if (!!element) optionsFlat.push(...element.values.map(x => ({
        infocode: element.label,
        uid: x.customizable_option_value_uid
      })));
    });
    var optionsFlatEnriched = optionsFlat.filter(x => !!optionProducts[x.uid]).map(x => _objectSpread({
      sku: optionProducts[x.uid].sku
    }, x));
    return optionsFlatEnriched;
  } else {
    return [];
  }
};
export function useExponea() {
  var _tableData$store, _tableData$store2;
  var [{
    cartId,
    details
  }] = useCartContext();
  var [{
    tableData
  }] = useCheckoutContext();
  var [{
    isSignedIn,
    currentUser,
    isApp
  }] = useUserContext();
  var [{
    products
  }] = useCatalogContext();
  var optionProducts = useMemo(() => getProductsByOptionUID(products), [products]);
  var prices = details && details.prices && {
    grand_total: details.prices.grand_total ? details.prices.grand_total.value : 0,
    subtotal_including_tax: details.prices.subtotal_including_tax ? details.prices.subtotal_including_tax.value : 0,
    discount: details.prices.discount ? details.prices.discount.value : 0
  };
  var items = products && details && details.items ? details.items.filter(x => !!x).map(x => ({
    sku: x.product.sku,
    product_name: x.product.frontend_title,
    quantity: x.quantity,
    selected_options: getOptionsFromItem(x.customizable_options, optionProducts, x.product)
  })) : [];
  var cartData = _objectSpread({
    cart_id: cartId,
    product_list: items,
    total_quantity: details === null || details === void 0 ? void 0 : details.total_quantity,
    store_id: (_tableData$store = tableData.store) === null || _tableData$store === void 0 ? void 0 : _tableData$store.id,
    store_name: (_tableData$store2 = tableData.store) === null || _tableData$store2 === void 0 ? void 0 : _tableData$store2.name,
    sales_type: tableData.sales_type,
    table: tableData === null || tableData === void 0 ? void 0 : tableData.table
  }, prices);
  var userData = _objectSpread({
    is_signed_in: isSignedIn,
    is_app: isApp
  }, currentUser && !!currentUser.email && {
    email: currentUser.email
  });
  var trackEvent = function (event_type, payload) {
    var trackCartData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var trackUserData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var callback = arguments.length > 4 ? arguments[4] : undefined;
    exponea.track(event_type, _objectSpread(_objectSpread(_objectSpread({
      timestamp: Date.now(),
      location: window.location.href,
      is_app: isApp
    }, trackCartData && cartData), trackUserData && userData), payload),
    //callback used to avoid skipping tracking, e.g window.location.replace
    callback, callback);
    Sentry.addBreadcrumb({
      category: "exponea",
      message: event_type,
      level: "info",
      data: _objectSpread(_objectSpread(_objectSpread({
        timestamp: Date.now(),
        location: window.location.href
      }, trackCartData && cartData), trackUserData && userData), payload)
    });
    return true;
  };
  return {
    trackEvent
  };
}