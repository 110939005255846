exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sliderFullPage-module-sliderFullPage .uk-dotnav{position:absolute;top:max(env(safe-area-inset-bottom, 0) + 48dvh,300px);min-height:14px;max-height:14px;max-width:100px;background-color:rgba(0,0,0,.1);border-radius:10px;padding-right:16px;transform:translate(16px, 0)}@media(min-width: 1120px){.sliderFullPage-module-sliderFullPage .uk-dotnav{bottom:20px}}.sliderFullPage-module-sliderFullPage .uk-dotnav a{border:1px solid #fffefe;background:rgba(0,0,0,0);width:6px;height:6px}.sliderFullPage-module-sliderFullPage .uk-dotnav>.uk-active>*{width:14px;background:#fffefe;border-radius:40px}.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content{top:max(env(safe-area-inset-bottom, 0) + 52dvh,320px)}@media(max-width: 330px){.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content{bottom:100px}}@media(min-width: 1120px)and (max-height: 694px){.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content{bottom:25px}}@media(min-width: 1120px)and (min-height: 695px){.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content{bottom:135px}}.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content .sliderFullPage-module-title{font-size:24px;margin-bottom:11px;margin-top:14px;font-weight:500}.sliderFullPage-module-sliderFullPage .sliderFullPage-module-content .sliderFullPage-module-caption{font-size:13px}@media(min-width: 1120px){.sliderFullPage-module-sliderFullPage{min-width:450px}}", ""]);

// Exports
exports.locals = {
	"sliderFullPage": "sliderFullPage-module-sliderFullPage",
	"content": "sliderFullPage-module-content",
	"title": "sliderFullPage-module-title",
	"caption": "sliderFullPage-module-caption"
};