import React from "react";

/**
 * @type {{
 *   app: import('firebase/app').FirebaseApp
 *   db: import('firebase/database').Database
 * }}
 */
var defaultValue = {
  app: null,
  db: null,
  messaging: null
};
var FirebaseContext = React.createContext(defaultValue);
export default FirebaseContext;