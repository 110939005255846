exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pickupPoint-module-root{box-shadow:0px 6px 15px rgba(0,0,0,.2);border-radius:10px;background-color:#e1d0b5;font-size:13px;text-align:left;position:relative;transition:all linear 300ms}.pickupPoint-module-root .image-container{height:100%}.pickupPoint-module-root .image-container img{width:100px;height:100%;object-fit:cover;border-radius:10px 0 0 10px}.pickupPoint-module-root .pickupPoint-module-content{position:relative;padding:16px 9px}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-title{font-weight:700}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-address{font-weight:400;line-height:17px}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-info{margin-top:8px;color:rgba(26,25,25,.5) !important}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-info i:before{opacity:.5}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-info .pickupPoint-module-distance{margin-bottom:8px}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-infoDot{width:25px;height:25px;border:1px solid #1a1919;border-radius:100%;color:#1a1919;background-color:rgba(0,0,0,0);position:absolute;font-weight:bold;top:5px;right:5px;display:flex;justify-content:center;align-items:center}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-infoDot span{font-size:15px;font-weight:bold}.pickupPoint-module-root .pickupPoint-module-content .pickupPoint-module-infoDot.pickupPoint-module-highlighted{opacity:0}.pickupPoint-module-root .pickupPoint-module-check{width:16px;height:16px;background-color:#fffefe;border-radius:50%;position:absolute;right:8px;top:5px;z-index:1}.pickupPoint-module-root .pickupPoint-module-check i{font-size:16px}.pickupPoint-module-root.pickupPoint-module-selectable{cursor:pointer}", ""]);

// Exports
exports.locals = {
	"root": "pickupPoint-module-root",
	"content": "pickupPoint-module-content",
	"title": "pickupPoint-module-title",
	"address": "pickupPoint-module-address",
	"info": "pickupPoint-module-info",
	"distance": "pickupPoint-module-distance",
	"infoDot": "pickupPoint-module-infoDot",
	"highlighted": "pickupPoint-module-highlighted",
	"check": "pickupPoint-module-check",
	"selectable": "pickupPoint-module-selectable"
};