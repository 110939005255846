var _unique = definitions => {
  var names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    var name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { CartPageFragment } from "../../cartPageFragments.gql";
export var GET_APPLIED_COUPONS = {
  "kind": "Document",
  "definitions": _unique([{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getAppliedCoupons"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "cartId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cart"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "cart_id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "cartId"
            }
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CartPageFragment"
            },
            "directives": []
          }]
        }
      }]
    }
  }].concat(CartPageFragment.definitions)),
  "loc": {
    "start": 0,
    "end": 150,
    "source": {
      "body": "\n    query getAppliedCoupons($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            ...CartPageFragment\n        }\n    }\n    \n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var APPLY_COUPON_MUTATION = {
  "kind": "Document",
  "definitions": _unique([{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "applyCouponToCart"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "cartId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "couponCode"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "applyCouponToCart"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "cart_id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "cartId"
                }
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "coupon_code"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "couponCode"
                }
              }
            }]
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "applyCouponToCart",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "cart"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CartPageFragment"
                },
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "available_payment_methods"
                },
                "arguments": [],
                "directives": [],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "code"
                    },
                    "arguments": [],
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    },
                    "arguments": [],
                    "directives": []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }].concat(CartPageFragment.definitions)),
  "loc": {
    "start": 0,
    "end": 503,
    "source": {
      "body": "\n    mutation applyCouponToCart($cartId: String!, $couponCode: String!) {\n        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) @connection(key: \"applyCouponToCart\") {\n            cart {\n                id\n                ...CartPageFragment\n                # If this mutation causes \"free\" to become available we need to know.\n                available_payment_methods {\n                    code\n                    title\n                }\n            }\n        }\n    }\n    \n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var REMOVE_COUPON_MUTATION = {
  "kind": "Document",
  "definitions": _unique([{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "removeCouponFromCart"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "cartId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "removeCouponFromCart"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "cart_id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "cartId"
                }
              }
            }]
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "removeCouponFromCart",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "cart"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CartPageFragment"
                },
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "available_payment_methods"
                },
                "arguments": [],
                "directives": [],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "code"
                    },
                    "arguments": [],
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    },
                    "arguments": [],
                    "directives": []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }].concat(CartPageFragment.definitions)),
  "loc": {
    "start": 0,
    "end": 464,
    "source": {
      "body": "\n    mutation removeCouponFromCart($cartId: String!) {\n        removeCouponFromCart(input: { cart_id: $cartId }) @connection(key: \"removeCouponFromCart\") {\n            cart {\n                id\n                ...CartPageFragment\n                # If this mutation causes \"free\" to become available we need to know.\n                available_payment_methods {\n                    code\n                    title\n                }\n            }\n        }\n    }\n    \n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};