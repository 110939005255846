import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import jsCookie from "js-cookie";
import { getCookiePersistenceDomain } from "../../overrides/peregrine/util/simplePersistence";
export var cookiesTypesId = {
  tecnicalCookie: "technicalCookie",
  analiticalCookie: "analyticalCookie",
  thirdPartyCookie: "thirdPartyCookie"
};
export var cookiesTypes = [{
  id: cookiesTypesId.tecnicalCookie,
  preselected: true,
  disabled: true
}, {
  id: cookiesTypesId.analiticalCookie,
  preselected: true,
  disabled: true
}, {
  id: cookiesTypesId.thirdPartyCookie,
  preselected: false,
  disabled: false
}];
export var setAllCookieSelected = doNotReload => {
  var cookiesValues = cookiesTypes.map(el => {
    return {
      id: el.id,
      value: true
    };
  }).reduce((prev, el) => {
    return _objectSpread(_objectSpread({}, prev), {}, {
      [el.id]: el.value
    });
  }, {});
  jsCookie.set("cookieConfig", JSON.stringify(cookiesValues), {
    expires: 10000,
    domain: getCookiePersistenceDomain()
  });

  // NOTE: this is made to enforce reload of values
  if (!doNotReload) {
    window.location.reload(true);
  }
};
export var setRequiredCookieSelected = () => {
  var cookiesValues = cookiesTypes.map(el => {
    return {
      id: el.id,
      value: el.id !== "thirdPartyCookie" ? true : false
    };
  }).reduce((prev, el) => {
    return _objectSpread(_objectSpread({}, prev), {}, {
      [el.id]: el.value
    });
  }, {});
  jsCookie.set("cookieConfig", JSON.stringify(cookiesValues), {
    expires: 10000,
    domain: getCookiePersistenceDomain()
  });
};
export var getDefaultCookieAcceptanceFromCookie = () => {
  try {
    var result = jsCookie.get("cookieConfig");
    if (result) {
      var resultParsed = JSON.parse(result);
      var cookiesValues = cookiesTypes.map(el => {
        return {
          id: el.id,
          value: !!(resultParsed && resultParsed[el.id])
        };
      }).reduce((prev, el) => {
        return _objectSpread(_objectSpread({}, prev), {}, {
          [el.id]: el.value
        });
      }, {});
      return cookiesValues;
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 *
 * @param {{
 *  [cookieType:string]:boolean
 * }} resultParsed
 */
export var saveCookieAcceptanceValues = (resultParsed, doNotReload) => {
  if (!resultParsed) {
    throw new Error("resultParsed not set");
  }
  var cookiesValues = cookiesTypes.map(el => {
    if (resultParsed[el.id] === undefined) {
      throw new Error("value for cookieType ".concat(el.id, " undefined"));
    }
    console.log("saveCookieAcceptanceValues el", el);
    return {
      id: el.id,
      value: !!resultParsed[el.id]
    };
  }).reduce((prev, el) => {
    console.log("saveCookieAcceptanceValues el", el);
    return _objectSpread(_objectSpread({}, prev), {}, {
      [el.id]: el.value
    });
  }, {});
  console.log("saveCookieAcceptanceValues cookiesValues", cookiesValues);
  jsCookie.set("cookieConfig", JSON.stringify(cookiesValues), {
    expires: 10000,
    domain: getCookiePersistenceDomain()
  });

  // NOTE: this is made to enforce reload of values
  if (!doNotReload) {
    window.location.reload(true);
  }
};
export var isThisCookieSupported = cookieType => {
  try {
    var allValues = getDefaultCookieAcceptanceFromCookie() || {};
    return !!allValues[cookieType];
  } catch (e) {
    console.error(e);
    return false;
  }
};
export var areCookieSetted = () => {
  var result = jsCookie.get("cookieConfig");
  return !!result;
};
export var isThirdPartyCookieSupported = isThisCookieSupported(cookiesTypesId.thirdPartyCookie);
export var isFirstPartyAnaliticsSupported = isThisCookieSupported(cookiesTypesId.analiticalCookie);