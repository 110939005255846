import { getISODay } from "date-fns";
export function isStoreOpen(hours) {
  var date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  var currentDow = getISODay(date);
  var todayHours = hours[currentDow];
  return todayHours.some(interval => {
    var [openHour, openMinute] = interval.open.split(':').map(Number);
    var [closeHour, closeMinute] = interval.close.split(':').map(Number);
    var openTime = new Date(date);
    openTime.setHours(openHour, openMinute, 0);
    var closeTime = new Date(date);
    closeTime.setHours(closeHour, closeMinute, 0);
    return date >= openTime && date < closeTime;
  });
}
export function getStoreStatus(hours) {
  var now = new Date();
  var today = getISODay(now);
  var tomorrow = today === 7 ? 1 : today + 1; //tomorrow is Monday if today is Sunday

  var todayHours = hours[today];
  var tomorrowHours = hours[tomorrow];
  var isOpen = isStoreOpen(hours, now);
  return {
    isOpen: isOpen,
    todayHours: todayHours,
    tomorrowHours: tomorrowHours
  };
}