import { useCallback, useEffect, useRef, useState } from "react";
import mergeOperations from "@magento/peregrine/lib/util/shallowMerge";
import DEFAULT_OPERATIONS from "./socialLogin.ggl";
import { useQuery } from "@apollo/client";
import { BUTTON_SHAPE } from "../constants";
import { useUserContext } from "@magento/peregrine/lib/context/user";
import { useHistory } from "react-router-dom";
import { env } from "build/env";
export var useSocialLogin = function () {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var {
    getStoreConfigQuery,
    getSocButtonsConfigsQuery
  } = operations;
  var [{
    isSignedIn
  }] = useUserContext();
  var prevIsSignedIn = useRef(isSignedIn);
  var history = useHistory();
  var {
    data: storeConfigData
  } = useQuery(getStoreConfigQuery, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  var [errors, setErrors] = useState(null);
  var handleErrors = useCallback(error => setErrors(error), [setErrors]);
  var {
    storeConfig
  } = storeConfigData || {};
  var {
    amSocialLoginButtonConfig: buttons
  } = {
    amSocialLoginButtonConfig: [{
      type: "google",
      label: "Google",
      url: env.MAGENTO_BACKEND_URL + "/amsociallogin/social/login/?type=google"
    }, {
      type: "facebook",
      label: "Facebook",
      url: env.MAGENTO_BACKEND_URL + "/amsociallogin/social/login/?type=facebook"
    }, {
      type: "apple",
      label: "Apple",
      url: env.MAGENTO_BACKEND_URL + "/amsociallogin/social/login/?type=apple"
    }]
  };
  var {
    amsociallogin_general_enabled: isEnabled,
    amsociallogin_general_button_shape,
    amsociallogin_general_button_position,
    amsociallogin_general_redirect_type: redirectType,
    amsociallogin_general_custom_url: redirectUrl,
    amsociallogin_general_login_position,
    amsociallogin_general_popup_enabled: popupEnabled
  } = storeConfig || {};
  var enabledModes = amsociallogin_general_login_position && amsociallogin_general_login_position.split(",");
  var needRedirect = redirectType === 1 && redirectUrl;
  useEffect(() => {
    if (popupEnabled && isSignedIn && prevIsSignedIn.current !== isSignedIn && needRedirect) {
      history.push("/".concat(redirectUrl.replace(/^\//, "")));
    }
    prevIsSignedIn.current = isSignedIn;
  }, [needRedirect, prevIsSignedIn, isSignedIn, redirectUrl, history, popupEnabled]);
  return {
    storeConfig,
    buttons,
    isEnabled: isEnabled && Array.isArray(buttons) && buttons.length,
    buttonShape: BUTTON_SHAPE[amsociallogin_general_button_shape || 0],
    buttonPosition: amsociallogin_general_button_position,
    needRedirect,
    errors,
    handleErrors,
    enabledModes
  };
};