exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".headerbasic-module-root{display:grid;grid-auto-columns:100%;grid-auto-flow:row;grid-auto-rows:auto;grid-row-gap:.5rem;position:absolute;left:0;right:0;top:0;padding-top:env(safe-area-inset-top, 0);z-index:10;text-transform:uppercase;font-family:\"Rubik\",sans-serif;font-size:13px;font-weight:bold;letter-spacing:.52px}.headerbasic-module-hasNavigationHeader{top:102px !important}.headerbasic-module-toolbar{align-content:center;align-items:center;display:grid;grid-template-areas:\"primary title secondary\";grid-template-columns:1fr auto 1fr;grid-template-rows:3rem;justify-items:center;min-height:3.5rem;padding:0 1rem}.icon-container i{font-size:20px}.headerbasic-module-black{color:#1a1919}.headerbasic-module-white{color:#fff}.headerbasic-module-buttonBack{padding:15px;margin-left:-16px}", ""]);

// Exports
exports.locals = {
	"root": "headerbasic-module-root",
	"hasNavigationHeader": "headerbasic-module-hasNavigationHeader",
	"toolbar": "headerbasic-module-toolbar",
	"black": "headerbasic-module-black",
	"white": "headerbasic-module-white",
	"buttonBack": "headerbasic-module-buttonBack"
};