import { createActions } from "redux-actions";
var prefix = "BOOKTABLE";
var actionTypes = ["BEGIN", "EDIT", "RESET"];
var actionMap = {
  SEARCH: {
    SUBMIT: null
  },
  BOOKING: {
    EDIT: null,
    ACCEPT: null,
    REJECT: null
  },
  BOOKING_HISTORY: {
    EDIT: null,
    REJECT: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});