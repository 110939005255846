exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".updateCustomerPage-module-root{border-radius:20px 20px 0px 0px}@media(min-width: 640px){.updateCustomerPage-module-root{border-radius:20px}}.updateCustomerPage-module-root .updateCustomerPage-module-next_time{cursor:pointer}.updateCustomerPage-module-fullfeedback_container{min-height:50dvh;background:var(--background-desktop);border-radius:10px}@media screen and (max-width: 640px){.updateCustomerPage-module-fullfeedback_container{border-radius:10px 10px 0px 0px;background:var(--background-mobile);background-size:cover !important;background-repeat:no-repeat !important;background-position:center}}.updateCustomerPage-module-title{padding-top:0;font-size:24px;margin-bottom:3px;font-weight:normal;line-height:1.17;text-align:center;margin-top:15px}.updateCustomerPage-module-info_text{margin:0 auto;padding:0 20px}.updateCustomerPage-module-info_text .updateCustomerPage-module-icon{font-size:24px}.updateCustomerPage-module-info_text .updateCustomerPage-module-status{font-size:13px;font-weight:bold;letter-spacing:.52px}.updateCustomerPage-module-info_text .updateCustomerPage-module-next_time{font-size:13px;font-weight:bold;letter-spacing:.52px;text-decoration:underline}.updateCustomerPage-module-button_container{margin-top:auto;margin-bottom:initial}.updateCustomerPage-module-button_container button{min-width:100%;line-height:64px;border-radius:0;margin:unset}.updateCustomerPage-module-feedback_fullScreen{margin-top:37px}@media(min-width: 640px){.updateCustomerPage-module-feedback_fullScreen{margin-top:27px}.updateCustomerPage-module-info_text{padding:0 100px}.updateCustomerPage-module-button_container{text-align:center;margin-bottom:auto}.updateCustomerPage-module-button_container button{border-radius:5px;line-height:48px;width:auto;min-width:inherit}}", ""]);

// Exports
exports.locals = {
	"root": "updateCustomerPage-module-root",
	"next_time": "updateCustomerPage-module-next_time",
	"fullfeedback_container": "updateCustomerPage-module-fullfeedback_container",
	"title": "updateCustomerPage-module-title",
	"info_text": "updateCustomerPage-module-info_text",
	"icon": "updateCustomerPage-module-icon",
	"status": "updateCustomerPage-module-status",
	"button_container": "updateCustomerPage-module-button_container",
	"feedback_fullScreen": "updateCustomerPage-module-feedback_fullScreen"
};