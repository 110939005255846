// TODO: rifare meglio e in un componente da inserire all'interno di drivers/adapter.js -> <BrowserRouter>
import { env } from "build/env";
import { isOrderActive } from "@Components/LinkBetween/miScusiAndTakeAwayUtils";

/**
 * Get the store code for a specific url
 *
 * @returns string
 */
export function getStoreCode() {
  return env.store_view_code;
}

/**
 * Get the store code for a specific url
 *
 * @returns object
 */
export function getStoreCodeHeadersContext(ls_store_id) {
  var storeCode = getStoreCode();
  var context = {
    context: {
      headers: {
        Store: "".concat(storeCode)
      }
    }
  };
  if (ls_store_id && isOrderActive) context.context.headers.LSStore = ls_store_id;
  return context;
}

/**
 * Get the language for a specific url
 *
 * @returns string
 */
export function getLanguage() {
  //const origin = location.origin;
  var pathname = location.pathname;
  var language = env.language;
  if (pathname.indexOf("/it") === 0) {
    language = "it_IT";
  } else if (pathname.indexOf("/en") === 0) {
    language = "en_US";
  } else if (pathname.indexOf("/es") === 0) {
    language = "es_ES";
  } else if (pathname.indexOf("/de") === 0) {
    language = "de_DE";
  }
  return language;
}

/**
 * Get the store code for a specific url
 *
 * @returns string
 */
export function getStoreCodeUrl() {
  switch (getLanguage()) {
    case "it_IT":
      return "it";
    case "en_US":
      return "";
    case "es_ES":
      return "en";
    case "de_DE":
      return "de";
    default:
      return "";
  }
}