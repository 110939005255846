exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".image-module-root{display:flex;justify-content:center}.image-module-container{position:relative;max-height:100%}.image-module-loaded{position:relative;top:0;left:0;visibility:visible}.image-module-notLoaded{visibility:hidden}.image-module-placeholder{background-color:rgb(var(--venia-global-color-gray));position:relative;top:0;left:0}.image-module-placeholder_layoutOnly{background-color:unset}", ""]);

// Exports
exports.locals = {
	"root": "image-module-root",
	"container": "image-module-container",
	"loaded": "image-module-loaded",
	"notLoaded": "image-module-notLoaded image-module-loaded",
	"placeholder": "image-module-placeholder",
	"placeholder_layoutOnly": "image-module-placeholder_layoutOnly image-module-placeholder"
};