import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * Overridden persistence layer with expiration based on localStorage and
 * redirect of certain keys towards shared cookie keys.
 */
import Cookies from "js-cookie";
export function getCookiePersistenceDomain() {
  var cookiePersistenceDomain = COOKIE_DOMAIN;
  return cookiePersistenceDomain;
}
class NamespacedLocalStorage {
  constructor(localStorage, key) {
    this.localStorage = localStorage;
    this.key = key;
  }
  _makeKey(key) {
    return "".concat(this.key, "__").concat(key);
  }
  getItem(name) {
    return this.localStorage.getItem(this._makeKey(name));
  }
  setItem(name, value) {
    return this.localStorage.setItem(this._makeKey(name), value);
  }
  removeItem(name) {
    return this.localStorage.removeItem(this._makeKey(name));
  }
}
export default class BrowserPersistence {
  constructor() {
    var localStorage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.localStorage;
    this.redirectCookie = true;
    this.redirectCookieKeys = ["signin_token", "cartId"];
    this.originalValueKeys = ["cartId"];
    this.key = this.constructor.KEY || BrowserPersistence.KEY;
    this.storage = new NamespacedLocalStorage(localStorage, this.constructor.KEY || BrowserPersistence.KEY);
  }
  _makeKey(key) {
    return "".concat(this.key, "__").concat(key);
  }
  getItem(name) {
    if (this.redirectCookie && this.redirectCookieKeys.includes(name)) {
      var cookieItem = Cookies.get("".concat(name, "_copy"));
      if (cookieItem) {
        // item a questo punto è presente nel cookie, porto in localStorage se manca
        var localStorageCopy = this.storage.getItem(name);
        if (!localStorageCopy) {
          var {
            value: _value,
            ttl: _ttl,
            timeStored: _timeStored,
            domainSource: _domainSource
          } = JSON.parse(cookieItem);
          this.storage.setItem(name, JSON.stringify({
            value: _value,
            timeStored: _timeStored,
            ttl: _ttl
          }));
          return this.originalValueKeys.includes(name) ? JSON.parse(cookieItem).value : cookieItem;
        }
      }
    }
    var now = Date.now();
    var item = this.storage.getItem(name);
    if (!item) {
      return undefined;
    }
    var {
      value,
      ttl,
      timeStored,
      domainSource
    } = JSON.parse(item);
    if (ttl && now - timeStored > ttl * 1000) {
      this.storage.removeItem(name);
      return undefined;
    }
    return value ? value : null;
  }
  setItem(name, value, ttl) {
    var timeStored = Date.now();
    if (this.redirectCookie && this.redirectCookieKeys.includes(name)) {
      // Store senza TTL per ridurre possibilità di overflow > 4096 byte
      // affido all'expire del browser il TTL
      var cookieTTL = 30;
      if (ttl) {
        // TTL settato (e.g. signin_token, 3600), shifto il 3600 a notazione js-cookie
        cookieTTL = new Date(new Date().getTime() + ttl * 1000);
      }
      Cookies.set("".concat(name, "_copy"), JSON.stringify({
        value,
        timeStored,
        ttl,
        domainSource: window.location.hostname
      }), {
        domain: getCookiePersistenceDomain(),
        expires: cookieTTL
      });
    }
    this.storage.setItem(name, JSON.stringify({
      value,
      timeStored,
      ttl
    }));
  }
  removeItem(name) {
    if (this.redirectCookie && this.redirectCookieKeys.includes(name)) {
      Cookies.remove("".concat(name, "_copy"), {
        domain: getCookiePersistenceDomain()
      });
    }
    this.storage.removeItem(name);
  }
}
_defineProperty(BrowserPersistence, "KEY", "M2_VENIA_BROWSER_PERSISTENCE");