import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSocialLoginContext } from "../context";
import { useUserContext } from "@magento/peregrine/lib/context/user";
import { getStoreCode } from "@Src_main/extensions/store-url-resolver/storeUrlResolver";
var VISIBLE_BUTTONS_COUNT = 3;
var getPopupParams = () => {
  var bottomPadding = 22;
  var screenX = window.screenX || window.screenLeft;
  var screenY = window.screenY || window.screenTop;
  var outerWidth = window.outerWidth || document.body.clientWidth;
  var outerHeight = window.outerHeight || document.body.clientHeight - bottomPadding;
  var width = 500;
  var height = 420;
  var leftDivider = 2;
  var topDivider = 2.5;
  var left = parseInt(screenX + (outerWidth - width) / leftDivider, 10);
  var top = parseInt(screenY + (outerHeight - height) / topDivider, 10);
  return "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top);
};
export var useSocButtons = function () {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var {
    buttons,
    mode,
    showCreateAccount
  } = props;
  var isShowMoreBtn = mode === "popup" && buttons.length > VISIBLE_BUTTONS_COUNT;
  var [showAll, setShowAll] = useState(!isShowMoreBtn);
  var {
    buttonShape,
    errors,
    handleErrors
  } = useSocialLoginContext();
  var [{
    isSignedIn,
    token
  }] = useUserContext();
  var errorRef = useRef(null);
  var storeCode = getStoreCode();
  var visibleButtons = useMemo(() => showAll ? buttons : buttons.slice(0, VISIBLE_BUTTONS_COUNT), [showAll, buttons]);
  var handleLogin = useCallback(button => {
    var {
      type,
      url
    } = button;
    handleErrors(null);
    window.open("".concat(url, "&isAjax=true&store=").concat(storeCode).concat(!isSignedIn ? "&generateToken=true" : "&token=".concat(token)), type, getPopupParams());
  }, [isSignedIn, handleErrors, token]);
  var handleShowMore = useCallback(() => setShowAll(true), [setShowAll]);
  useEffect(() => {
    var el = errorRef && errorRef.current;
    if (errors) {
      var {
        redirectWithError
      } = errors;
      if (redirectWithError && showCreateAccount && typeof showCreateAccount === "function") {
        handleErrors(_objectSpread(_objectSpread({}, errors), {}, {
          redirectWithError: 0
        }));
        showCreateAccount();
      }
      if (el && mode === "popup") {
        el.scrollIntoView();
      }
    }
    return () => {
      if (errors && !errors.redirectWithError) {
        handleErrors(null);
      }
    };
  }, [errors, errorRef, showCreateAccount, handleErrors, mode]);
  return {
    handleLogin,
    visibleButtons,
    isShowMoreBtn: isShowMoreBtn && !showAll,
    handleShowMore,
    buttonShape,
    errors,
    errorRef
  };
};