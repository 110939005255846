import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import { gql } from "@apollo/client";
export var GET_STORE_CONFIG = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getStoreConfigs {\n        storeConfig {\n            store_code\n            amsociallogin_general_enabled\n            amsociallogin_general_login_position\n            amsociallogin_general_button_shape\n            amsociallogin_general_popup_enabled\n            amsociallogin_general_button_position\n            amsociallogin_general_redirect_type\n            amsociallogin_general_custom_url\n        }\n    }\n"])));
export var GET_SOC_BUTTONS_CONFIG = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query amSocialLoginButtonConfig {\n        amSocialLoginButtonConfig {\n            type\n            label\n            url\n        }\n    }\n"])));
export var GET_SOC_ACCOUNT_DATA = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query amSocialLoginAccountData {\n        amSocialLoginAccountData {\n            type\n            name\n        }\n    }\n"])));
export var UNLINK_ACCOUNT = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation amSocialLoginUnlinkAccount($type: String!) {\n        amSocialLoginUnlinkAccount(type: $type) {\n            isSuccess\n            message\n        }\n    }\n"])));
export var LINK_ACCOUNT = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    mutation amSocialLoginLinkAccount($type: String!) {\n        amSocialLoginLinkAccount(type: $type) {\n            isSuccess\n            message\n        }\n    }\n"])));
export default {
  getStoreConfigQuery: GET_STORE_CONFIG,
  getSocButtonsConfigsQuery: GET_SOC_BUTTONS_CONFIG,
  getSocAccountDataQuery: GET_SOC_ACCOUNT_DATA,
  unlinkAccountMutation: UNLINK_ACCOUNT,
  linkAccountMutation: LINK_ACCOUNT
};