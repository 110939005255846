import { createActions } from "redux-actions";
var prefix = "USER";
var actionTypes = ["RESET", "SET_TOKEN", "CLEAR_TOKEN", "SET_GUEST_CART_ID"];
var actionMap = {
  SIGN_IN: {
    REQUEST: null,
    RECEIVE: null
  },
  GET_DETAILS: {
    REQUEST: null,
    RECEIVE: null
  },
  CREATE_ACCOUNT: {
    REQUEST: null,
    RECEIVE: null
  },
  RESET_PASSWORD: {
    REQUEST: null,
    RECEIVE: null
  },
  IS_SIGNING_IN: {
    SUBMIT: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});