import { env } from "build/env";
export var isOrderActive = window.location.host.startsWith(env.URL_TAKEAWAY);
export var isMiscusiMainActive = !isOrderActive;
export var pushToMiScusi = (historyPush, url) => {
  if (!(historyPush && historyPush.bind)) {
    throw new Error("the first argument should be the actual history.push");
  }
  isMiscusiMainActive ? historyPush(url || "/") : window.location.href = "".concat(env.URL_MISCUSI).concat(url || "/");
};
export var homepageUrl = isOrderActive ? "/homepage" : "/";
export var pushToOrderDomain = () => {
  var {
    URL_TAKEAWAY
  } = env;
  if (isMiscusiMainActive) {
    var orderDomainUrl = "".concat(window.location.protocol, "//").concat(URL_TAKEAWAY).concat(window.location.pathname).concat(window.location.search);
    window.location.assign(orderDomainUrl);
  }
};
export var pushToWebsiteHomepage = () => {
  var {
    URL_MISCUSI
  } = env;
  if (isOrderActive) {
    var websiteHomepageUrl = "".concat(window.location.protocol, "//").concat(URL_MISCUSI);
    window.location.assign(websiteHomepageUrl);
  }
};