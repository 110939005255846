exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".radio-module-full_width_radio{width:100%}.radio-module-full_width_radio span{font-size:14px}.radio-module-input{background:none;background-clip:content-box;border:2px solid #992631;border-radius:50%;height:22px;min-width:22px;margin:5px;position:relative;width:22px;-webkit-appearance:none;cursor:pointer}.radio-module-input:checked{background:radial-gradient(circle at center, #992631 0%, #992631 47%, #992631 47%, transparent 55%, transparent 55%);border-color:#992631}.radio-module-input:focus{outline:none}.radio-module-input .radio-module-label_normal{color:rgba(26,25,25,.5);font-size:13px;align-items:center;line-height:1.38;text-align:left}.radio-module-input .radio-module-label_bold{color:#1a1919;font-size:13px;align-items:center;line-height:1.38;text-align:left}", ""]);

// Exports
exports.locals = {
	"full_width_radio": "radio-module-full_width_radio",
	"input": "radio-module-input",
	"label_normal": "radio-module-label_normal",
	"label_bold": "radio-module-label_bold"
};