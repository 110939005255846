import { VALID_SERVICE_WORKER_ENVIRONMENT, handleMessageFromSW } from "@magento/venia-ui/lib/util/swUtils";
export var registerSW = () => {
  var isIE = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
  if (VALID_SERVICE_WORKER_ENVIRONMENT && !isIE) {
    navigator.serviceWorker.register("/sw.js").then(() => {
      console.log("SW Registered");
    }).catch(() => {
      /**
       * console.* statements are removed by webpack
       * in production mode. window.console.* are not.
       */
      window.console.warn("Failed to register SW.");
    });
    navigator.serviceWorker.addEventListener("message", e => {
      var {
        type,
        payload
      } = e.data;
      handleMessageFromSW(type, payload, e);
    });
  }
};