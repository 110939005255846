import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import actions from "../actions/cart";
export var name = "cart";
export var initialState = {
  addItemError: null,
  cartId: null,
  details: {},
  detailsError: null,
  getCartError: null,
  isLoading: false,
  isUpdatingItem: false,
  isAddingItem: false,
  removeItemError: null,
  shippingMethods: [],
  updateItemError: null,
  isMiniCartOpen: false,
  outOfStockClean: false
};
var reducerMap = {
  [actions.getCart.receive]: (state, _ref) => {
    var {
      payload,
      error
    } = _ref;
    if (error) {
      return _objectSpread(_objectSpread({}, initialState), {}, {
        getCartError: payload
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      cartId: String(payload),
      getCartError: null
    });
  },
  [actions.getDetails.request]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isLoading: true
    });
  },
  [actions.getDetails.receive]: (state, _ref2) => {
    var {
      payload,
      error
    } = _ref2;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        detailsError: payload,
        isLoading: false
      });
    }
    return _objectSpread(_objectSpread(_objectSpread({}, state), payload), {}, {
      isLoading: false
    });
  },
  [actions.addItem.request]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isAddingItem: true
    });
  },
  [actions.addItem.receive]: (state, _ref3) => {
    var {
      payload,
      error
    } = _ref3;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        addItemError: payload,
        isAddingItem: false
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      isAddingItem: false
    });
  },
  [actions.updateItem.request]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isUpdatingItem: true
    });
  },
  [actions.updateItem.receive]: (state, _ref4) => {
    var {
      payload,
      error
    } = _ref4;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdatingItem: false,
        updateItemError: payload
      });
    }

    // We don't actually have to update any items here
    // because we force a refresh from the server.
    return _objectSpread(_objectSpread({}, state), {}, {
      isUpdatingItem: false
    });
  },
  [actions.removeItem.receive]: (state, _ref5) => {
    var {
      payload,
      error
    } = _ref5;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        removeItemError: payload
      });
    }
    return _objectSpread({}, state);
  },
  [actions.setIsMiniCartOpen.recieve]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), {}, {
      isMiniCartOpen: payload
    });
  },
  [actions.outOfStockClean.receive]: (state, _ref7) => {
    var {
      payload
    } = _ref7;
    return _objectSpread(_objectSpread({}, state), {}, {
      outOfStockClean: payload
    });
  },
  [actions.reset]: () => initialState
};
export default handleActions(reducerMap, initialState);