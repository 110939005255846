import { createActions } from "redux-actions";
var prefix = "CART";
var actionMap = {
  ADD_ITEM: {
    REQUEST: null,
    RECEIVE: null
  },
  GET_CART: {
    REQUEST: null,
    RECEIVE: null
  },
  GET_DETAILS: {
    REQUEST: null,
    RECEIVE: null
  },
  REMOVE_ITEM: {
    REQUEST: null,
    RECEIVE: null
  },
  UPDATE_ITEM: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_IS_MINI_CART_OPEN: {
    REQUEST: null,
    RECIEVE: null
  },
  OUT_OF_STOCK_CLEAN: {
    REQUEST: null,
    RECEIVE: null
  }
};
var actionTypes = ["BEGIN_EDIT_ITEM", "END_EDIT_ITEM", "RESET"];
export default createActions(actionMap, ...actionTypes, {
  prefix
});