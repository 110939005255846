import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import mergeOperations from "@magento/peregrine/lib/util/shallowMerge";
import { useAppContext } from "@magento/peregrine/lib/context/app";
import { useCatalogContext } from "@magento/peregrine/lib/context/catalog";
import DEFAULT_OPERATIONS from "./navigation.gql";
var ancestors = {
  CREATE_ACCOUNT: "SIGN_IN",
  FORGOT_PASSWORD: "SIGN_IN",
  MY_ACCOUNT: "MENU",
  SIGN_IN: "MENU",
  MENU: null
};
export var useNavigation = function () {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var {
    getRootCategoryId
  } = operations;
  // retrieve app state from context
  var [appState, {
    closeDrawer
  }] = useAppContext();
  var [catalogState] = useCatalogContext();
  var {
    data: getRootCategoryData
  } = useQuery(getRootCategoryId, {
    fetchPolicy: "cache-and-network"
  });
  var rootCategoryId = useMemo(() => {
    if (getRootCategoryData) {
      return getRootCategoryData.storeConfig.root_category_id;
    }
  }, [getRootCategoryData]);

  // extract relevant data from app state
  var {
    drawer
  } = appState;
  var isOpen = drawer === "nav";
  var {
    categories
  } = catalogState;

  // get local state
  var [view, setView] = useState("MENU");
  var [categoryId, setCategoryId] = useState(rootCategoryId);
  useEffect(() => {
    // On a fresh render with cold cache set the current category as root
    // once the root category query completes.
    if (rootCategoryId && !categoryId) {
      setCategoryId(rootCategoryId);
    }
  }, [categoryId, rootCategoryId]);

  // define local variables
  var category = categories[categoryId];
  var isTopLevel = categoryId === rootCategoryId;
  var hasModal = view !== "MENU";

  // define handlers
  var handleBack = useCallback(() => {
    var parent = ancestors[view];
    if (parent) {
      setView(parent);
    } else if (category && !isTopLevel) {
      setCategoryId(category.parentId);
    } else {
      closeDrawer();
    }
  }, [category, closeDrawer, isTopLevel, view]);
  var handleClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);
  return {
    handleBack,
    handleClose,
    hasModal,
    isOpen,
    isTopLevel,
    view
  };
};