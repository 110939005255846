exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".weglotInit-module-weglotInit.weglotInit-module-local .country-selector{transform:none !important}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-default,.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-default .country-selector{top:13px;position:fixed;max-height:1px}.weglotInit-module-weglotInit.weglotInit-module-mobile .weglot-container{display:inline-block;z-index:3}.weglotInit-module-weglotInit.weglotInit-module-mobile .weglot_switcher{transform:translateX(4vw);transition:all 192ms ease}.weglotInit-module-weglotInit.weglotInit-module-mobile .weglot_switcher.inactiveNav{transform:translateX(100vw)}.weglotInit-module-weglotInit.weglotInit-module-mobile .weglot_switcher.staticNav{transform:translateX(4vw) !important}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector{background-color:rgba(0,0,0,0)}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector .wgcurrent{border:none}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector .wgcurrent::after{display:none}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector .wgcurrent a{padding:0 10px 0 10px}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector .wgcurrent ul{background:rgba(0,0,0,0);border-color:rgba(0,0,0,0);overflow:visible}.weglotInit-module-weglotInit.weglotInit-module-mobile .wg-drop.country-selector .wg-flags a img.wg-flag{width:33px;height:33px;border-radius:50%;margin-right:0;border:2px solid #e1d0b5;box-shadow:0 0px 15px 0px rgba(0,0,0,.3)}.weglotInit-module-weglotInit.weglotInit-module-desktop .wg-default .country-selector{bottom:0;position:fixed;right:5px;border-radius:10px;background-color:rgba(255,255,255,.5) !important}.weglotInit-module-weglotInit.weglotInit-module-desktop .wg-default .country-selector #weglot-listbox{background-color:rgba(255,255,255,.5) !important}", ""]);

// Exports
exports.locals = {
	"weglotInit": "weglotInit-module-weglotInit",
	"local": "weglotInit-module-local",
	"mobile": "weglotInit-module-mobile",
	"desktop": "weglotInit-module-desktop"
};