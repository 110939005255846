import { createActions } from "redux-actions";
var prefix = "CHECKOUT";
var actionTypes = ["BEGIN", "EDIT", "RESET"];

// classify action creators by domain
// e.g., `actions.order.submit` => CHECKOUT/ORDER/SUBMIT
// a `null` value corresponds to the default creator function
var actionMap = {
  BILLING_ADDRESS: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  SHIPPING_ADDRESS: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  PAYMENT_METHOD: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  AVAILABLE_PAYMENT_METHODS: {
    SUBMIT: null
  },
  PAYMENT_METHOD_COMPONENT_SELECTED: {
    SUBMIT: null
  },
  GET_SHIPPING_METHODS: {
    REQUEST: null,
    RECEIVE: null
  },
  RECEIPT: {
    SET_ORDER: null,
    RESET: null
  },
  SHIPPING_METHOD: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  ORDER: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  PICKUP_POINT: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  PICKUP_POINT_AVAILABILITY: {
    SUBMIT: null
  },
  CHECKOUT_STEP_COMPLETED: {
    SUBMIT: null
  },
  TABLE_DATA: {
    SUBMIT: null
  },
  PICKUP_TIME: {
    SUBMIT: null
  },
  ADYEN_CONFIG: {
    SUBMIT: null
  },
  ADYEN_CHECKOUT: {
    SUBMIT: null
  },
  ADYEN_STATE: {
    SUBMIT: null
  },
  ADYEN_ADDITIONAL_DETAILS: {
    SUBMIT: null
  },
  STORED_SHIPPING_DATA: {
    SUBMIT: null
  },
  COVER_SKU: {
    SUBMIT: null
  },
  TABLE_STATUS: {
    SUBMIT: null
  },
  HOSPITALITY_DATA: {
    SUBMIT: null
  },
  PAY_AT_STORE_SELECTED: {
    SUBMIT: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});