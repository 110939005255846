import { distance } from "./generalUtils";
export function getSearchParamsFromUrl() {
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var result = {};
  for (var [key, value] of urlParams) {
    result[key] = value;
  }
  return result;
}
export function getTableData() {
  var searchParams = getSearchParamsFromUrl();
  var orderKeys = ["cps", "cpt", "sales_type", "initialCoversSet", "overrideCoversInCartFromContext"];
  var orderKeysAvailable = Object.keys(searchParams).filter(x => orderKeys.includes(x));
  var filtered = Object.keys(searchParams).filter(key => orderKeysAvailable.includes(key)).reduce((obj, key) => {
    obj[key] = searchParams[key];
    return obj;
  }, {});
  if (filtered.cps && filtered.cpt && !filtered.sales_type) {
    filtered["sales_type"] = "CASUALDINING";
  }
  if (Object.keys(filtered).length) {
    return filtered;
  } else {
    return {};
  }
}
export function getFilteredSortedStores(stores, choosedPosition) {
  if ((stores === null || stores === void 0 ? void 0 : stores.length) > 0) {
    if (choosedPosition) {
      // Sort by distance
      var newPickupPoints = [...stores].sort((a, b) => distance(choosedPosition.latitude, choosedPosition.longitude, a.latitude, a.longitude) - distance(choosedPosition.latitude, choosedPosition.longitude, b.latitude, b.longitude));
      return newPickupPoints;
    } else {
      return stores;
    }
  }
  return [];
}