exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".myAccountInfo-module-myAccountInfo .myAccountInfo-module-label{margin-bottom:8px}.myAccountInfo-module-myAccountInfo .address-autocomplete{background-color:rgba(0,0,0,0);margin-bottom:27px}.myAccountInfo-module-myAccountInfo .myAccountInfo-module-gender{display:flex}.myAccountInfo-module-myAccountInfo .myAccountInfo-module-gender i::before{color:#f9f7f6}@media(min-width: 1120px){.myAccountInfo-module-myAccountInfo{flex-direction:column;max-width:500px;margin:auto;align-items:center}.myAccountInfo-module-myAccountInfo .myAccountInfo-module-info{width:50%}}", ""]);

// Exports
exports.locals = {
	"myAccountInfo": "myAccountInfo-module-myAccountInfo",
	"label": "myAccountInfo-module-label",
	"gender": "myAccountInfo-module-gender",
	"info": "myAccountInfo-module-info"
};