var loadGmaps = callback => {
  var existingScript = document.getElementById("gmapsJS");
  if (!existingScript) {
    var script = document.createElement("script");
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB7OjyZZr6U8I578A0po3zcOXiDVfpyjM8&libraries=places";
    script.id = "gmapsJS";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadGmaps;