import { env } from "build/env";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
export class GoogleTagManagerC extends Component {
  componentDidMount() {
    try {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js"
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", env.GA_CODE);
      var existingScript = document.getElementById("gtagJS");
      if (!existingScript) {
        var script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + env.GOOGLE_ADS_CODE;
        script.id = "gtagJS";
        document.body.appendChild(script);
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', env.GOOGLE_ADS_CODE, {
            'debug_mode': true
          });
          window.gtag = gtag;
        };
      }
    } catch (e) {
      console.error(e);
    }
  }
  render() {
    return null;
  }
}
export var GoogleTagManager = withRouter(GoogleTagManagerC);
export default GoogleTagManager;