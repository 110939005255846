exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".checkbox-module-area .checkbox-module-root{display:grid;gap:.5rem;grid-template-columns:auto auto;justify-content:start;position:relative;cursor:pointer}.checkbox-module-area .checkbox-module-root.checkbox-module-breakLabels>div{display:flex;flex-direction:column}.checkbox-module-area .checkbox-module-more{font-size:11px;margin-left:40px;cursor:pointer}.checkbox-module-area .checkbox-module-link{text-decoration:underline;font-size:12px;margin-left:calc(31px + .5rem)}.checkbox-module-area .checkbox-module-link:hover{opacity:.6}.checkbox-module-input{background:none;background-clip:content-box;border:1px solid #992631;border-radius:50%;margin-top:5px;cursor:pointer;position:relative;min-width:24px;height:24px;-webkit-appearance:none}.checkbox-module-inputChecked{background-color:#992631;border-color:rgba(0,0,0,0)}.checkbox-module-icon{display:block;position:absolute;font-size:24px;padding-left:4px;padding-top:4px;width:24px;height:24px}.checkbox-module-icon .icon-container{width:24px;text-align:center}.checkbox-module-icon .icon-container i{position:relative;line-height:24px}.checkbox-module-label_normal{color:rgba(26,25,25,.5);font-size:13px;align-items:center;line-height:1.38;text-align:left}.checkbox-module-label_normal a{color:#1a1919}.checkbox-module-label_normal a:hover{color:rgba(26,25,25,.5)}.checkbox-module-label_bold{color:#1a1919;font-size:16px;align-items:center;line-height:1.38;text-align:left}", ""]);

// Exports
exports.locals = {
	"area": "checkbox-module-area",
	"root": "checkbox-module-root",
	"breakLabels": "checkbox-module-breakLabels",
	"more": "checkbox-module-more",
	"link": "checkbox-module-link",
	"input": "checkbox-module-input",
	"inputChecked": "checkbox-module-inputChecked checkbox-module-input",
	"icon": "checkbox-module-icon",
	"label_normal": "checkbox-module-label_normal",
	"label_bold": "checkbox-module-label_bold"
};