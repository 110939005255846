import { useEffect, useState } from "react";
var getSize = () => {
  var safeAreaInsetTop = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top')) || 0; // Fallback value
  var safeAreaInsetBottom = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom')) || 0; // Fallback value
  var safeAreaInsetLeft = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-left')) || 0;
  var safeAreaInsetRight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-right')) || 0;
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
    safeAreaInsetTop,
    safeAreaInsetBottom,
    safeAreaInsetLeft,
    safeAreaInsetRight
  };
};
export var WindowSizeContextProvider = {};

/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
export function useWindowSize() {
  var MOBILE_SIZE_THRESHOLD = 1120;
  var SMARTPHONE_SIZE_THRESHOLD = 640;

  // Initialize state with the size values and insets
  var [windowSize, setWindowSize] = useState(getSize());
  var [isMobile, setIsMobile] = useState(windowSize.innerWidth < MOBILE_SIZE_THRESHOLD);
  var [isSmartphone, setIsSmartphone] = useState(windowSize.innerWidth < SMARTPHONE_SIZE_THRESHOLD);
  useEffect(() => {
    var handleResize = () => {
      var newSize = getSize();

      // Update the state only if something has changed
      if (newSize.innerWidth !== windowSize.innerWidth || newSize.innerHeight !== windowSize.innerHeight) {
        setWindowSize(newSize);
        setIsMobile(newSize.innerWidth < MOBILE_SIZE_THRESHOLD);
        setIsSmartphone(newSize.innerWidth < SMARTPHONE_SIZE_THRESHOLD);
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]); // Only run if windowSize changes

  // Return the window size data and additional flags
  return {
    innerWidth: windowSize.innerWidth,
    innerHeight: windowSize.innerHeight,
    outerWidth: windowSize.outerWidth,
    outerHeight: windowSize.outerHeight,
    isMobile,
    isSmartphone,
    safeAreaInsetTop: windowSize.safeAreaInsetTop,
    safeAreaInsetBottom: windowSize.safeAreaInsetBottom,
    safeAreaInsetLeft: windowSize.safeAreaInsetLeft,
    safeAreaInsetRight: windowSize.safeAreaInsetRight
  };
}